

export namespace Mimes {
	export const text = 'text/plain';
	export const binary = 'application/octet-stream';
	export const unknown = 'application/unknown';
	export const markdown = 'text/markdown';
	export const latex = 'text/latex';
	export const uriList = 'text/uri-list';
}
